import React, { FC } from 'react';

import { DangerouslySetInnerHtml } from 'layout';
import Image from 'common/Image';
import Button from 'components/Button';

import { useScrollTo } from 'hooks/useScrollTo';

import { CampaignProductBannerProps } from './models';

import './CampaignProductBanner.scss';

const CampaignProductBanner: FC<CampaignProductBannerProps> = ({
  label,
  background,
  backgroundMobile,
  description,
  buttons,
  productImage,
  disclaimer,
  title,
  text,
}) => {
  const [handleScrollTo] = useScrollTo();

  return (
    <div className="campaignProductBanner">
      <Image
        {...background.imageStructure}
        className="campaignProductBanner__image campaignProductBanner__image--desktop"
      />
      <Image
        {...backgroundMobile.imageStructure}
        className="campaignProductBanner__image campaignProductBanner__image--mobile"
      />
      <div className="campaignProductBanner__content">
        <div className="content">
          <Image {...label.imageStructure} className="campaignProductBanner__label" />
          <Image {...productImage.imageStructure} className="campaignProductBanner__productImage" />
          <DangerouslySetInnerHtml
            html={description}
            className="campaignProductBanner__description"
          />
          <div className="campaignProductBanner__buttons">
            {buttons.map((button) => {
              const queryString = button.url?.includes('#') ? `#${button.url?.split('#')[1]}` : '';

              return (
                <Button
                  {...button}
                  key={button.ariaLabel}
                  className="campaignProductBanner__button"
                  onClick={() => handleScrollTo(queryString)}
                >
                  {button?.label}
                </Button>
              );
            })}
          </div>
          <div className="campaignProductBanner__disclaimer">{disclaimer}</div>
        </div>
      </div>
      <div className="campaignProductBanner__bottom">
        <DangerouslySetInnerHtml html={title} className="campaignProductBanner__title" />
        <DangerouslySetInnerHtml html={text} className="campaignProductBanner__text" />
      </div>
    </div>
  );
};

export default CampaignProductBanner;
