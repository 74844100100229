import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Layout } from 'layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Banner from 'components/Banner';
import { SliderVariant } from 'components/Slider';
import Carousel from 'containers/Carousel';
import CarouselCampaignBanner from 'containers/CarouselCampaignBanner';
import VideoSection from 'containers/VideoSection';

import sortArr from 'utils/sortArr';

import './HomePage.scss';

const HomePage: FC<HomepageTypes.HomePageProps> = ({
  data: {
    homePage: {
      urls,
      seo,
      heroBanner,
      campaignBanner,
      twoColumnsBanner,
      infographicBanner,
      articleCarousel,
      productCarousel,
      videoSection,
      challengeBanner,
      bannerWithoutCropp,
    },
    allProductDetail: { productsList },
    allArticleDetail: { articlesList },
  },
}) => {
  const { title: seoTitle, keywords, description } = seo;

  const { ARTICLES, PRODUCTS } = SliderVariant;

  return (
    <Layout headerTransparent className="home-page">
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={seoTitle}
        data={{
          metaTitle: seoTitle,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      {heroBanner ? <Banner {...heroBanner} className="home-page__banner--hero-high" /> : null}
      {bannerWithoutCropp ? (
        <Banner
          imageBannerDesktop={bannerWithoutCropp.imageBannerDesktop}
          imageBannerTablet={bannerWithoutCropp.imageBannerTablet}
          imageBannerMobile={bannerWithoutCropp.imageBannerMobile}
          {...bannerWithoutCropp.banner[0]}
        />
      ) : null}
      {campaignBanner ? <CarouselCampaignBanner {...{ campaignBanner }} /> : null}
      <Banner
        {...twoColumnsBanner}
        banerColor="white"
        className="home-page__banner--two-sided"
        isContain
      />
      <Banner {...infographicBanner} className="home-page__banner--infographic" />
      <VideoSection {...{ videoSection }} />
      <Banner {...challengeBanner} className="home-page__banner--challenge" />
      {articlesList.length && articleCarousel ? (
        <Carousel
          carouselItems={sortArr(articlesList, 'articleCardOrder')}
          carouselVariant={ARTICLES}
          carousel={articleCarousel}
        />
      ) : null}
      {productsList.length && productCarousel ? (
        <Carousel
          carouselItems={productsList}
          carouselVariant={PRODUCTS}
          carousel={productCarousel}
        />
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String, $productsUrls: [String], $articlesUrls: [String]) {
    homePage(lang: { eq: $lang }) {
      seo {
        ...SEOStructureFragment
      }
      urls {
        ...UrlsFragment
      }
      heroBanner {
        ...BannerFragment
      }
      campaignBanner {
        ...CarouselCampaignBannerFragment
      }
      twoColumnsBanner {
        ...BannerFragment
      }
      infographicBanner {
        ...BannerFragment
      }
      videoSection {
        ...VideoSectionFragment
      }
      articleCarousel {
        ...CarouselFragment
      }
      productCarousel {
        ...CarouselFragment
      }
      challengeBanner {
        ...BannerFragment
      }
      bannerWithoutCropp {
        banner {
          ...BannerFragment
        }
        imageBannerDesktop {
          alt
          imageData {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        imageBannerMobile {
          alt
          imageData {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        imageBannerTablet {
          alt
          imageData {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    allProductDetail(filter: { url: { in: $productsUrls } }) {
      productsList: nodes {
        ...ProductCardFragment
      }
    }
    allArticleDetail(filter: { url: { in: $articlesUrls } }) {
      articlesList: nodes {
        ...ArticleCardFragment
        articleCardOrder
      }
    }
  }
`;

export default HomePage;
