import React, { FC } from 'react';

import CampaignProductBanner from 'components/CampaignProductBanner/CampaignProductBanner';
import Slider, { SliderVariant } from 'components/Slider';

import { CarouselCampaignBannerProps } from './models.d';

import './CarouselCampaignBanner.scss';

const { BANNER } = SliderVariant;

const CarouselCampaignBanner: FC<CarouselCampaignBannerProps> = ({ campaignBanner }) => {
  const { items } = campaignBanner;

  const slidesArray = items?.map((item, id) => ({
    id,
    component: <CampaignProductBanner {...item} />,
  }));

  return (
    <div className="carousel-product-banner">
      {items.length > 1 ? (
        <Slider slides={slidesArray} variant={BANNER} sliderId="campaignBanner" />
      ) : (
        <CampaignProductBanner {...items[0]} />
      )}
    </div>
  );
};

export default CarouselCampaignBanner;
